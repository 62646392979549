import {
	Box,
	Card,
	CardBody,
	Flex,
	HStack,
	Icon,
	Stack,
	Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { IoArrowForwardSharp } from 'react-icons/io5';

import BaseLink from '@/components/utils/links/BaseLink';

/**
 * Landing page main
 *
 * @return {*}  {JSX.Element}
 */
const LandingPageMain = (): JSX.Element => {
	return (
		<Flex
			direction="column"
			align={['center', 'start']}
			mt={['72px', '100px']}
			gap={2}
		>
			<Text color="green.600" fontSize={['28px', '32px']} fontWeight={700}>
				Selamat Datang
			</Text>
			<Text
				color="gray.600"
				fontSize={['12px', '14px']}
				fontWeight={700}
				align={['center', 'start']}
			>
				Realizing Education’s PromiseMadrasah Education Quality Reform
			</Text>
			<Text
				color="gray.600"
				fontSize={['12px', '14px']}
				fontWeight={700}
				align={['center', 'start']}
				mt={['40px', '73px']}
			>
				Pilih untuk masuk aplikasi
			</Text>
			<Stack mt={4} gap={4}>
				<BaseLink href="https://edm-fe.erkam-v2.kemenag.go.id/login">
					<Card bg="white" w={['full', '445px']} borderRadius={8}>
						<CardBody>
							<Box px={4}>
								<HStack gap={4}>
									<Text
										color="green.600"
										fontSize={['24px', '32px']}
										fontWeight={700}
									>
										EDM V2
									</Text>
									<Icon
										as={IoArrowForwardSharp}
										boxSize="20px"
										color="green.600"
									/>
								</HStack>
								<Text
									color="gray.600"
									fontSize={['12px', '14px']}
									fontWeight={700}
								>
									Evaluasi Diri Madrasah TA 2022
								</Text>
							</Box>
						</CardBody>
					</Card>
				</BaseLink>
				<BaseLink href="/login">
					<Card bg="white" w={['full', '445px']}>
						<CardBody>
							<Box px={4}>
								<HStack gap={4}>
									<Text
										color="green.600"
										fontSize={['24px', '32px']}
										fontWeight={700}
									>
										ERKAM-NG
									</Text>
									<Icon
										as={IoArrowForwardSharp}
										boxSize="20px"
										color="green.600"
									/>
								</HStack>
								<Text
									color="gray.600"
									fontSize={['12px', '14px']}
									fontWeight={700}
								>
									Rencana Kerja & Anggaran Madrasah TA. 2023
								</Text>
							</Box>
						</CardBody>
					</Card>
				</BaseLink>
			</Stack>
		</Flex>
	);
};

export default LandingPageMain;
