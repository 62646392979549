import { GetServerSidePropsContext } from 'next';
import { getServerSession } from 'next-auth/next';
import * as React from 'react';

import LandingPageMain from '@/components/landing_page/LandingPageMain';
import UnauthedAppLayout from '@/components/layouts/public/UnauthedAppLayout';

import { authOptions } from '@/pages/api/auth/[...nextauth]';
import { NextPageWithLayout } from '@/pages/page';

export const getServerSideProps = async (
	context: GetServerSidePropsContext
) => {
	const session = await getServerSession(context.req, context.res, authOptions);

	if (session) {
		return {
			redirect: {
				destination: '/dashboard',
				permanent: false,
			},
		};
	}

	return {
		props: {
			session,
		},
	};
};

/**
 * Landing page for the application.
 *
 * @return {*}
 */
const IndexPage: NextPageWithLayout = () => {
	return <LandingPageMain />;
};

IndexPage.getLayout = (page) => {
	return <UnauthedAppLayout overridePageTitle>{page}</UnauthedAppLayout>;
};

export default IndexPage;
