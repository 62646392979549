import { Box, Flex, Link, Text } from '@chakra-ui/react';

/**
 * Footer page
 *
 * @return {*}  {JSX.Element}
 */
const Footer = () => {
	return (
		<Box
			py={10}
			px={{ base: 10, md: 6 }}
			color="var(--gray-400, #A0AEC0)"
			fontSize={{ base: 'xs', lg: 'sm' }}
			textAlign={{ base: 'center', md: 'left' }}
		>
			<Flex
				justifyContent={{ base: 'center', md: 'space-around' }}
				py={{ base: 4, md: 7, lg: 10 }}
				direction={{ base: 'column', md: 'row' }}
			>
				<Text>
					@ 2023, dibuat dengan ❤️ oleh{' '}
					<Text as="span" fontWeight="bold" color="green">
						KEMENAG <span color="var(--gray-400, #A0AEC0)">&</span> SCRUM TEAM
					</Text>{' '}
					untuk madrasah yang lebih baik
				</Text>
				<Flex
					fontSize={{ base: 'xs', lg: 'sm' }}
					gap={10}
					mt={{ base: 3, md: 0 }}
					justifyContent={{ base: 'center', lg: 'start' }}
				>
					<Link href="#">Live Chat</Link>
					<Link href="#">Dokumentasi</Link>
				</Flex>
			</Flex>
		</Box>
	);
};

export default Footer;
