import { Box, Flex, Img } from '@chakra-ui/react';
import Head from 'next/head';
import * as React from 'react';

import LogoErkamLogin from '@/components/assets/LogoErkamLogin';
import Footer from '@/components/layouts/public/components/Footer';
export interface AppLayoutProps {
	children: React.ReactNode;
	pageTitle?: string;
	overridePageTitle?: boolean;
	filename?: string;
}

const UnauthedAppLayout: React.FC<AppLayoutProps> = ({
	children,
	pageTitle = 'ERKAM NG',
	overridePageTitle,
	filename,
}) => {
	const pageTitleFormatted = overridePageTitle
		? pageTitle
		: `${pageTitle} - eRKAM NG`;

	return (
		<Box h="100vh" w="full">
			<Head>
				<title>{pageTitleFormatted}</title>
			</Head>
			<main>
				<Flex direction="column" h="100vh" bg="#F8F9FA">
					<Box mx={['auto', '76px']} mt="44px">
						<Img src="/images/logo-erkam-long.png" alt="Logo" w="126px" />
					</Box>
					<Flex flex="1">
						<Box width={{ base: 'full', md: '55%' }} mx={['40px', '76px']}>
							{children}
						</Box>
						<Box width="45%" display={{ base: 'none', md: 'block' }}>
							<LogoErkamLogin filename={filename} />
						</Box>
					</Flex>
					<Footer />
				</Flex>
			</main>
		</Box>
	);
};

export default UnauthedAppLayout;
