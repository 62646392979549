import { Box, Img } from '@chakra-ui/react';

export interface LogoErkamLoginProps {
	filename?: string;
}
/**
 * Logo Erkam Login
 *
 * @return {*}  {JSX.Element}
 */

const LogoErkamLogin: React.FC<LogoErkamLoginProps> = ({ filename }) => {
	const containerStyles = {
		width: 'full',
		flexShrink: 0,
		borderRadius: '65px 65px 0px 65px',
		background: '#25855A',
		marginTop: '-12rem',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '90vh',
	};

	return (
		<Box style={containerStyles}>
			{filename && (
				<Img src={`/images/${filename}`} alt="" width={138} height={138} />
			)}
		</Box>
	);
};

export default LogoErkamLogin;
